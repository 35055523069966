<template>
  <v-overlay
    :model-value="true"
    :scrim="false"
    persistent
    scroll-strategy="none"
    no-click-animation
    width="400"
    :content-props="{ style: { top: '5rem', right: '1rem' } }">
    <package-progress
      v-show="dialogs.has('packageProgress')"></package-progress>
    <prod-alert v-show="dialogs.has('prodAlert')"></prod-alert>
    <system-info-alert
      v-show="dialogs.has('systemInfoAlert')"></system-info-alert>
  </v-overlay>
</template>

<script lang="ts">
import { provide, ref } from 'vue'
import { openDialogKey, closeDialogKey } from './injectionKeys'
import PackageProgress from './package-progress/Index.vue'
import ProdAlert from './ProdAlert.vue'
import { DialogName } from './types'
import SystemInfoAlert from '@/components/permanent-dialog/SystemInfoAlert.vue'

export default {
  name: 'PackageProgressDialog',
  components: { SystemInfoAlert, PackageProgress, ProdAlert },
  setup() {
    const dialogs = ref(new Set<DialogName>())

    const openDialog = (dialogName: DialogName) => {
      console.log('openDialog', dialogName)
      dialogs.value.add(dialogName)
    }
    const closeDialog = (dialogName: DialogName) => {
      console.log('closeDialog', dialogName)
      dialogs.value.delete(dialogName)
    }

    provide(openDialogKey, openDialog)
    provide(closeDialogKey, closeDialog)

    return {
      dialogs,
    }
  },
}
</script>

<style scoped lang="scss"></style>
