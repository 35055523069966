<template>
  <div>
    <b-select
      v-model="tariffExtensionId"
      :items="
        $directory.get('extensionTariffs', dialog.dialogData.tariff_extension)
      "
      label="Розширення тарифу"
      :item-title="t => `${t.name} (к-сть перевірки боржників - ${t.number})`"
      :error-messages="getErrorMessages(v$.tariffExtensionId)"
      :loading="$loading.isLoading('extensionTariffs')"
      @blur="v$.tariffExtensionId.$touch()"></b-select>
  </div>
</template>

<script lang="ts">
import { PropType, ref } from 'vue'
import { Dialog } from 'best-modules/plugins'
import { required } from '@vuelidate/validators'
import { useVuelidate } from '@vuelidate/core'
import {
  createCompanyExtensionTariff,
  updateCompanyExtensionTariff,
} from '@/request/company'
import { getErrorMessages } from 'best-modules/utils'
import { fillDirectory } from '@/directory'

export default {
  name: 'CompanyExtensionTariff',
  props: {
    dialog: {
      type: Object as PropType<Dialog<'CompanyExtensionTariff'>>,
    },
  },
  methods: { getErrorMessages },
  setup(props) {
    const tariffExtensionId = ref<number>(
      props.dialog.dialogData.tariffExtensionId
    )

    const rules = {
      tariffExtensionId: { required },
    }

    const v$ = useVuelidate(rules, { tariffExtensionId })

    const submit = () => {
      if (props.dialog.action === 'create') {
        return createCompanyExtensionTariff({
          tariffExtensionId: tariffExtensionId.value,
          companyTariffId: props.dialog.dialogData.companyTariffId,
        })
      }
      if (props.dialog.action === 'update') {
        return updateCompanyExtensionTariff(
          props.dialog.dialogData.id,
          tariffExtensionId.value
        )
      }
    }

    fillDirectory('extensionTariffs')

    return { tariffExtensionId, submit, v$ }
  },
}
</script>

<style scoped lang="scss"></style>
