import { Query, sendRequest } from 'best-modules/utils'
import {
  Company,
  CompanyAddress,
  CompanyCredentials,
  CompanyToken,
  CompanyTokenCredentials,
  PaginationList,
  User,
} from '@/utils/types'

const getCompanyList = (
  query: Query<'search' | 'typeId'>
): Promise<PaginationList<Company>> => {
  return sendRequest({
    name: 'companyList',
    url: { url: '/admin/company', query },
  })
}
const getCompanySingle = (companyId: string): Promise<Company> => {
  return sendRequest({
    name: 'companySingle',
    url: `/admin/company/single/${companyId}`,
  })
}
const createCompany = (company: CompanyCredentials): Promise<Company> => {
  return sendRequest({
    name: 'companyCreate',
    url: '/admin/company/create',
    method: 'post',
    payload: company,
  })
}
const updateCompany = (company: Company): Promise<Company> => {
  return sendRequest({
    name: 'companyCreate',
    url: `/admin/company/update/${company.id}`,
    method: 'put',
    payload: company,
  })
}
const deleteCompany = (companyId: string): Promise<void> => {
  return sendRequest({
    name: 'companyCreate',
    url: `/admin/company/delete/${companyId}`,
    method: 'delete',
  })
}

const createUserCompanyRole = (company, companyId) => {
  return sendRequest({
    name: 'createByCompany',
    url: '/admin/user/createByCompany',
    method: 'post',
    payload: { ...company, companyId },
  })
}

const changeCompany = (id: string): Promise<User> => {
  return sendRequest({
    name: 'changeCompany',
    url: `/admin/user/changeCompany/${id}`,
    method: 'get',
  })
}

const deleteUserCompanyRole = (id: string): Promise<void> => {
  return sendRequest({
    name: 'deleteCompanyUserRole',
    url: `/admin/companyUserRole/delete/${id}`,
    method: 'delete',
  })
}

const createCompanyAddress = (
  address: CompanyAddress
): Promise<CompanyAddress> => {
  return sendRequest({
    name: 'createCompanyAddress',
    url: '/admin/address/create',
    method: 'post',
    payload: address,
  })
}

const deleteCompanyAddress = (id: string): Promise<void> => {
  return sendRequest({
    name: 'deleteCompanyAddress',
    url: `/admin/address/delete/${id}`,
    method: 'delete',
  })
}

const updateCompanyAddress = (
  address: CompanyAddress
): Promise<CompanyAddress> => {
  return sendRequest({
    name: 'updateCompanyAddress',
    url: `/admin/address/update/${address.id}`,
    method: 'post',
    payload: address,
  })
}

const createCompanyToken = (
  tokenCredentials: CompanyTokenCredentials
): Promise<{
  token: CompanyToken
  plainTextToken: string
}> => {
  return sendRequest({
    name: 'createCompanyToken',
    url: '/admin/integrationToken/create',
    method: 'post',
    payload: tokenCredentials,
  })
}

const updateCompanyToken = (
  token: CompanyTokenCredentials,
  tokenId: number
): Promise<CompanyToken> => {
  return sendRequest({
    name: 'updateCompanyToken',
    url: `/admin/integrationToken/update/${tokenId}`,
    method: 'post',
    payload: token,
  })
}
const deleteCompanyToken = (tokenId: number): Promise<void> => {
  return sendRequest({
    name: 'deleteCompanyToken',
    url: `/admin/integrationToken/update/${tokenId}`,
    method: 'delete',
  })
}

const createCompanyTariff = (payload: {
  tariffId: number
  companyId: string
  period: string
}): Promise<Company> => {
  return sendRequest({
    name: 'createCompanyTariff',
    url: '/admin/companyTariff/create',
    method: 'post',
    payload: payload,
  })
}
const updateCompanyTariff = (
  companyTariffId: string,
  tariffId: number
): Promise<Company> => {
  return sendRequest({
    name: 'updateCompanyTariff',
    url: `/admin/companyTariff/update/${companyTariffId}`,
    method: 'post',
    payload: { tariffId },
  })
}

const deleteCompanyTariff = (companyTariffId: string): Promise<Company> => {
  return sendRequest({
    name: 'deleteCompanyTariff',
    url: `/admin/companyTariff/delete/${companyTariffId}`,
    method: 'delete',
  })
}

const updateCompanySignatureAlgo = (
  companyId: string,
  signatureAlgoId: number
): Promise<Company> => {
  return sendRequest({
    name: 'updateCompanySignatureAlgo',
    url: `/admin/company/signatureAlgo/set/${companyId}`,
    method: 'post',
    payload: { signatureAlgoId },
  })
}

const createCompanyExtensionTariff = (credentials: {
  companyTariffId: string
  tariffExtensionId: number
}) => {
  return sendRequest({
    name: 'createCompanyExtensionTariff',
    url: '/admin/companyTariffExtension/create',
    method: 'post',
    payload: credentials,
  })
}

const updateCompanyExtensionTariff = (
  id: number,
  tariffExtensionId: number
) => {
  return sendRequest({
    name: 'createCompanyExtensionTariff',
    url: `/admin/companyTariffExtension/update/${id}`,
    method: 'post',
    payload: { tariffExtensionId },
  })
}

const deleteCompanyExtensionTariff = (id: number) => {
  return sendRequest({
    name: 'deleteCompanyExtensionTariff',
    url: `/admin/companyTariffExtension/delete/${id}`,
    method: 'delete',
  })
}

export {
  getCompanyList,
  getCompanySingle,
  createCompany,
  updateCompany,
  deleteCompany,
  changeCompany,
  createUserCompanyRole,
  deleteUserCompanyRole,
  createCompanyAddress,
  deleteCompanyAddress,
  updateCompanyAddress,
  createCompanyToken,
  updateCompanyToken,
  deleteCompanyToken,
  createCompanyTariff,
  updateCompanyTariff,
  deleteCompanyTariff,
  updateCompanySignatureAlgo,
  createCompanyExtensionTariff,
  updateCompanyExtensionTariff,
  deleteCompanyExtensionTariff,
}
