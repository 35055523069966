<template>
  <div>
    <div class="d-flex justify-end mb-3">
      <v-btn @click="createTariff">
        <span>Додати тариф</span>
        <v-icon class="ml-2">mdi-plus</v-icon>
      </v-btn>
    </div>
    <v-data-table
      :headers="companyTariffsHeaders"
      :items="company.company_tariffs"
      item-value="id">
      <template #item.year="{ item }">
        {{ new Period(item.period).year }}
      </template>
      <template #item.month="{ item }">
        {{ new Period(item.period).getMonth() }}
      </template>
      <template #item.packageLimit="{ item }">
        <span>
          {{ item.tariffId === 1 ? 10 : 'Безліміт' }}
        </span>
      </template>
      <template #item.successPackagesCount="{ item }">
        <span>
          {{ rankSpacing(item.successPackagesCount) }}
        </span>
      </template>
      <template #item.sentPackagesCount="{ item }">
        <span>
          {{ rankSpacing(item.sentPackagesCount) }}
        </span>
      </template>
      <template #item.checkDebtorLimit="{ item }">
        <span v-if="item.tariffId === 1"> 10 </span>
        <span v-if="item.tariffId === 2"> 50 </span>
        <span v-if="item.tariffId === 3"> 70 </span>
      </template>
      <template #item.successDebtorCheckCount="{ item }">
        <span>
          {{ rankSpacing(item.successDebtorCheckCount) }}
        </span>
      </template>
      <template #item.sentDebtorCheckCount="{ item }">
        <span>
          {{ rankSpacing(item.sentDebtorCheckCount) }}
        </span>
      </template>
      <template #item.company_tariff_extensions="{ item }">
        <v-menu open-on-hover>
          <template #activator="{ props }">
            <v-btn
              variant="plain"
              size="small"
              density="comfortable"
              icon="mdi-information-variant"
              v-bind="props"></v-btn>
          </template>

          <v-card>
            <v-card-text>
              <div v-if="item.company_tariff_extensions.length">
                <div
                  v-for="(
                    extensionTariff, idx
                  ) in item.company_tariff_extensions"
                  :key="extensionTariff.id">
                  <div class="d-flex justify-space-between align-center ga-3">
                    <div class="text-no-wrap">
                      <span>{{ extensionTariff.tariff_extension.name }}</span>
                      <span class="text-disabled">
                        (к-сть перевірки боржників
                        {{ extensionTariff.tariff_extension.number }})
                      </span>
                    </div>
                    <div class="d-flex align-center ga-1">
                      <v-btn
                        v-tooltip="'Редагувати розширення для тарифу'"
                        icon="mdi-pencil"
                        size="small"
                        variant="plain"
                        rounded="circle"
                        density="comfortable"
                        @click="updateExtensionTariff(extensionTariff)"></v-btn>
                      <v-btn
                        v-tooltip="'Видалити розширення для тарифу'"
                        icon="mdi-delete-outline"
                        size="small"
                        variant="plain"
                        rounded="circle"
                        density="comfortable"
                        color="error"
                        :loading="
                          $loading.isLoading('deleteCompanyExtensionTariff')
                        "
                        @click="
                          deleteExtensionTariff(item, extensionTariff.id)
                        "></v-btn>
                    </div>
                  </div>
                  <v-divider
                    v-if="idx < item.company_tariff_extensions.length - 1"
                    class="my-1"></v-divider>
                </div>
              </div>
              <div v-else class="text-disabled">Розширення тарифу відсутні</div>
            </v-card-text>
          </v-card>
        </v-menu>
      </template>

      <template #item.actions="{ item }">
        <div class="h-100">
          <b-action-menu
            :actions="[
              {
                title: 'Редагувати',
                icon: 'mdi-pencil',
                action: () => updateTariff(item),
              },
              {
                title: 'Видалити',
                icon: 'mdi-delete-outline',
                action: () => deleteTariff(item),
              },
              {
                title: 'Додати розширення тарифу',
                icon: 'mdi-plus',
                action: () => createExtensionTariff(item.id),
                disabled: !isActualPeriod(item),
              },
            ]"></b-action-menu>
        </div>
      </template>
    </v-data-table>
  </div>
</template>

<script lang="ts">
import { PropType } from 'vue'
import { CachedObjectProxy, rankSpacing } from 'best-modules/utils'
import { Company, CompanyExtensionTariff } from '@/utils/types'
import { companyTariffsHeaders } from '@/utils/tableHeaders'
import { openDialog } from '@/plugins/dialog'
import { Period } from '@/utils/helpers'
import { BActionMenu } from 'best-modules/components'
import { CompanyTariff } from '@/utils/types'
import {
  deleteCompanyExtensionTariff,
  deleteCompanyTariff,
} from '@/request/company'
import { confirm } from 'best-modules/plugins'

export default {
  name: 'Tariffs',
  components: { BActionMenu },
  props: {
    company: {
      type: Object as PropType<CachedObjectProxy<Company>>,
      required: true,
    },
  },
  data: () => {
    return { companyTariffsHeaders, Period }
  },
  methods: { rankSpacing },
  setup(props) {
    const createTariff = () => {
      return openDialog({
        name: 'CompanyTariff',
        action: 'create',
        dialogData: {
          companyId: props.company.id,
        },
        params: {
          cb: (updatedCompany: Company) => {
            props.company.$set(updatedCompany)
          },
        },
      })
    }

    const updateTariff = (tariff: CompanyTariff) => {
      return openDialog({
        name: 'CompanyTariff',
        action: 'update',
        dialogData: {
          companyId: props.company.id,
          companyTariffId: tariff.id,
          tariffId: tariff.tariffId,
          period: tariff.period,
        },
        params: {
          cb: (updatedCompany: Company) => {
            props.company.$set(updatedCompany)
          },
        },
      })
    }

    const deleteTariff = (tariff: CompanyTariff) => {
      const tariffPeriod = `${new Period(tariff.period).getMonth()} ${new Period(tariff.period).year}`

      return confirm({
        text: `Ви впевнені що хочете видалити тариф ${tariff.tariff.name} за ${tariffPeriod}?`,
      }).then(() => {
        deleteCompanyTariff(tariff.id).then(updatedCompany => {
          props.company.$set(updatedCompany)
        })
      })
    }

    const createExtensionTariff = (tariffId: string) => {
      return openDialog({
        name: 'CompanyExtensionTariff',
        action: 'create',
        dialogData: {
          companyTariffId: tariffId,
        },
        params: {
          cb: (updatedCompany: Company) => {
            props.company.$set(updatedCompany)
          },
        },
      })
    }

    const updateExtensionTariff = (extensionTariff: CompanyExtensionTariff) => {
      return openDialog({
        name: 'CompanyExtensionTariff',
        action: 'update',
        dialogData: extensionTariff,
        params: {
          cb: (updatedCompany: Company) => {
            props.company.$set(updatedCompany)
          },
        },
      })
    }

    const deleteExtensionTariff = (
      tariff: CompanyTariff,
      extensionTariffId: number
    ) => {
      const tariffPeriod = `${new Period(tariff.period).getMonth()} ${new Period(tariff.period).year}`

      return confirm({
        text: `Ви впевнені що хочете видалити розширення для тарифу ${tariff.tariff.name} за ${tariffPeriod}?`,
      }).then(() => {
        deleteCompanyExtensionTariff(extensionTariffId).then(updatedCompany => {
          props.company.$set(updatedCompany)
        })
      })
    }

    const isActualPeriod = (tariff: CompanyTariff) => {
      const currentMonth = new Date().getMonth() + 1
      const currentYear = new Date().getFullYear()

      const [tariffYear, tariffMonth] = tariff.period.split('-')

      return +tariffMonth >= currentMonth && +tariffYear >= currentYear
    }

    return {
      createTariff,
      updateTariff,
      deleteTariff,
      createExtensionTariff,
      updateExtensionTariff,
      deleteExtensionTariff,
      isActualPeriod,
    }
  },
}
</script>

<style scoped lang="scss"></style>
