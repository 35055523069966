import type { DataTableHeader } from 'best-modules/components'

const userListHeaders: DataTableHeader[] = [
  {
    title: 'ПІБ',
    key: 'name',
  },
  {
    title: 'Телефон',
    key: 'phone',
    sortable: false,
  },
  {
    title: 'Email',
    key: 'email',
    sortable: false,
  },
]

const companyListHeaders: DataTableHeader[] = [
  {
    title: 'Тип',
    key: 'type.name',
  },
  {
    title: 'Назва',
    key: 'shortName',
  },
  {
    title: 'ЄДРПОУ',
    key: 'edrpou',
  },
  {
    title: 'Дії',
    key: 'actions',
    align: 'end',
    sortable: false,
  },
]

const reportListHeaders: DataTableHeader[] = [
  {
    title: 'Період',
    key: 'period',
    sortable: false,
  },
  {
    title: 'Назва',
    key: 'name',
    sortable: false,
  },
  {
    title: 'Компанія',
    key: 'company.shortName',
    sortable: false,
  },
  {
    title: 'ЄДРПОУ',
    key: 'company.edrpou',
    align: 'end',
    sortable: false,
  },
]

const packageListHeaders: DataTableHeader[] = [
  {
    title: 'Код',
    key: 'code',
    sortable: false,
  },
  {
    title: 'Клієнт',
    key: 'client',
    sortable: false,
  },
  {
    title: 'Статус',
    key: 'status',
    sortable: false,
  },
  {
    title: 'Статус події клієнта',
    key: 'person.f150_event',
    sortable: false,
  },
  {
    title: 'ЄДРПОУ/ІПН',
    key: 'inn',
    sortable: false,
  },
  {
    title: 'Дата створення',
    key: 'created_at',
    sortable: false,
  },
  {
    title: 'Автор',
    key: 'author',
    sortable: false,
  },
  {
    title: 'Дії',
    key: 'actions',
    align: 'end',
    sortable: false,
  },
]

const packageDirectoryHeaders: DataTableHeader[] = [
  {
    title: 'Назва показника',
    key: 'name',
  },
  {
    title: 'Код показника',
    key: 'code',
  },
  {
    title: 'Дата початку',
    key: 'openDate',
  },
  {
    title: 'Дата закінчення',
    key: 'closeDate',
  },
]

const validationTHead: DataTableHeader[] = [
  {
    title: 'Поле',
    key: 'key',
  },
  {
    title: 'Текст',
    key: 'error',
  },
]

const companyEmployeesHeaders: DataTableHeader[] = [
  { title: 'Користувач', key: 'user' },
  { title: 'Роль', key: 'role' },
  { title: 'Дії', key: 'actions', align: 'end', sortable: false },
]

const userCompaniesTHead: DataTableHeader[] = [
  { title: 'Назва', key: 'company.shortName' },
  { title: 'Роль', key: 'role' },
]

const companyTokenHeaders: DataTableHeader[] = [
  { title: 'Назва', key: 'name' },
  { title: 'Дата створення', key: 'created_at' },
  { title: 'Дійсний до', key: 'expires_at' },
  { title: 'Дії', key: 'actions', align: 'end', sortable: false },
]

const companyTariffsHeaders: DataTableHeader[] = [
  { title: 'Тариф', key: 'tariff.name' },
  { title: 'Місяць', key: 'month' },
  { title: 'Рік', key: 'year' },
  {
    title: 'Ліміт пакетів',
    key: 'packageLimit',
  },
  {
    title: 'Використано пакетів',
    key: 'successPackagesCount',
  },
  {
    title: 'Надіслано пакетів',
    key: 'sentPackagesCount',
  },
  {
    title: 'Ліміт перевірки боржників',
    key: 'checkDebtorLimit',
  },
  {
    title: 'Розширення тарифу',
    key: 'company_tariff_extensions',
    sortable: false,
  },
  {
    title: 'Перевірено боржників',
    key: 'successDebtorCheckCount',
  },
  {
    title: 'Надіслано запитів на перевірку боржника',
    key: 'sentDebtorCheckCount',
  },
  { title: 'Дії', key: 'actions', align: 'end', sortable: false },
]

const debtorPledgeHeaders: DataTableHeader[] = [
  {
    key: 'orderZastava',
    title: '№ застави',
  },
  {
    key: 's031',
    title: 'Вид забезпечення за договором',
  },
  {
    key: 'pledgeDay',
    title: 'Дата укладання договору',
    align: 'end',
  },
]

const checkDebtorListHeaders: DataTableHeader[] = [
  {
    key: 'result.name',
    title: 'Статус пошуку',
    sortable: false,
  },
  {
    key: 'credential.name',
    title: 'Тип пошуку',
    sortable: false,
  },
  {
    key: 'credentialValue',
    title: 'Значення пошуку',
    sortable: false,
  },
  {
    key: 'company',
    title: 'Компанія',
    sortable: false,
  },
  {
    key: 'author',
    title: 'Користувач',
    sortable: false,
  },
  {
    key: 'created_at',
    title: 'Дата пошуку',
    sortable: false,
  },
]

const nbuErrorsHeaders: DataTableHeader[] = [
  {
    title: 'Поле',
    key: 'name',
  },
  {
    title: 'Номер контроля НБУ',
    key: 'NBUControlId',
  },
  {
    title: 'Ідентифікатор поля НБУ',
    key: 'NBURequisiteId',
  },
  {
    title: 'Дата',
    key: 'created_at',
  },
]

export {
  userListHeaders,
  companyListHeaders,
  reportListHeaders,
  packageListHeaders,
  packageDirectoryHeaders,
  companyTokenHeaders,
  validationTHead,
  companyEmployeesHeaders,
  userCompaniesTHead,
  companyTariffsHeaders,
  debtorPledgeHeaders,
  checkDebtorListHeaders,
  nbuErrorsHeaders,
}
