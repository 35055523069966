<template>
  <v-card elevation="12" class="mt-2">
    <v-card-text>
      <h4>Увага!</h4>
      <span class="mt-3 d-block">
        У системі запроваджено нову редакцію шаблона Excel (для імпорту) з
        поточними оновленнями. <br />
        Переконайтеся, що використовуєте актуальну версію для коректної роботи.
      </span>
      <div class="d-flex justify-end">
        <v-btn variant="plain" @click="closeDialog('systemInfoAlert')">
          Закрити
        </v-btn>
      </div>
    </v-card-text>
  </v-card>
</template>

<script lang="ts">
import { openDialogKey, closeDialogKey } from './injectionKeys'
import { inject } from 'vue'
export default {
  name: 'SystemInfoAlert',
  setup() {
    const openDialog = inject(openDialogKey)
    const closeDialog = inject(closeDialogKey)
    if (location.hostname !== 'cr-nbu.com.ua') {
      openDialog('systemInfoAlert')
    }

    return { closeDialog }
  },
}
</script>

<style scoped lang="scss"></style>
