<template>
  <div class="d-flex justify-end mb-3">
    <v-btn
      v-tooltip="'Завантажити помилки валідації'"
      icon="mdi-printer-outline"
      variant="plain"
      density="comfortable"
      :loading="$loading.isLoading('exportValidation')"
      @click="exportValidation([dialog.dialogData.packageId])"></v-btn>
  </div>
  <v-data-table-virtual
    :items-per-page="-1"
    :headers="validationTHead"
    :items="modifyItems">
    <template #bottom> </template>
    <template #[`item.key`]="{ item }">
      <v-tooltip :text="item.key">
        <template #activator="{ props }">
          <div v-bind="props">{{ item.key.split('.').pop() }}</div>
        </template>
      </v-tooltip>
    </template>
    <template #[`item.error`]="{ item }">
      <div v-for="e in item.value" :key="e" class="mb-3">{{ e }}</div>
    </template>
  </v-data-table-virtual>

  <div class="d-flex justify-end mt-5">
    <v-btn @click="dialog.close()">Редагувати</v-btn>
  </div>
</template>

<script lang="ts">
import { exportValidation } from '@/request/report'
import { validationTHead } from '@/utils/tableHeaders'
import { ValidationError } from '@/pages/report/package/package-table/types'
import { computed, PropType } from 'vue'
import { Dialog } from 'best-modules/plugins'
import { keepArray } from 'best-modules/utils/helpers'

export default {
  name: 'PackageValidationErrors',
  props: {
    dialog: {
      type: Object as PropType<Dialog<'PackageValidationErrors'>>,
    },
  },
  methods: { exportValidation },
  setup(props) {
    const modifyItems = computed(() => {
      const errors = props.dialog.dialogData.validationErrors
      return Object.entries(errors).map(([key, value]) => {
        const formattedValue = Array.isArray(value)
          ? value.map(er => getValidationError(er))
          : getValidationError(value as string)
        return {
          key: key,
          value: keepArray(formattedValue),
        }
      })
    })

    const getValidationError = (error: ValidationError | string): string => {
      switch (error) {
        case 'Min':
          return 'Не відповідає мінімальній кількості символів'
        case 'Regex':
          return 'Не допустимий формат даних'
        case 'Exists':
          return 'Недопустиме значення довідника'
        case 'Required':
          return 'Поле обовʼязкове для заповнення'
        case 'Max':
          return 'Не відповідає максимальній кількості символів'
        case 'DateFormat':
          return 'Не коректно вказана дата'
        default:
          return error
      }
    }

    return {
      validationTHead,
      modifyItems,
      getValidationError,
    }
  },
}
</script>
