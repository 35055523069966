<template>
  <div>
    <div class="d-flex gap-3 mb-3">
      <a class="link" :href="nbuControlLink" target="_blank">
        Завантажити контроль НБУ
      </a>
      <a
        class="link"
        href="https://docs.google.com/document/d/1xwj62wLSZD5JdD661SbzixBI6DKp_IbHhzfLPz__GAg/edit?usp=sharing"
        target="_blank">
        Інструкція
      </a>
    </div>
    <v-data-table
      :headers="nbuErrorsHeaders"
      :items="NBUErrors"
      items-value="id">
    </v-data-table>
    <div class="d-flex justify-end">
      <span v-if="isRetired" class="link" @click="isRetired = false">
        Показати тільки нові
      </span>
      <span v-else class="link" @click="isRetired = true">Показати всі</span>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, PropType, ref } from 'vue'
import { Dialog } from 'best-modules/plugins'
import { CustomDate } from 'best-modules/utils'
import { NBUError } from '@/utils/types'
import { useUser } from '@/store/user'
import { nbuErrorsHeaders } from '@/utils/tableHeaders'

export default {
  name: 'NBUErrors',
  props: {
    dialog: {
      type: Object as PropType<Dialog<'NBUErrors'>>,
    },
  },
  data() {
    return {
      nbuErrorsHeaders,
    }
  },
  methods: { CustomDate },
  setup(props) {
    const { userData } = useUser()
    const isRetired = ref(false)

    const nbuControlLink = computed<string>(() => {
      return userData.watch_company_user_role.company.typeId === 1
        ? 'https://surveys.bank.gov.ua/about-NBUReestr/files/Controls_FC.xlsx'
        : 'https://surveys.bank.gov.ua/about-NBUReestr/files/Controls_CU.xlsx'
    })

    const NBUErrors = computed<NBUError[]>(() => {
      const items =
        props.dialog.dialogData.NBUErrors.map(e => {
          return {
            ...e,
            created_at: new CustomDate(e.created_at.substring(0, 19)).toString({
              time: true,
            }),
          }
        }) || []

      return items.filter(i => isRetired.value || !i.isRetired)
    })

    return { NBUErrors, nbuControlLink, isRetired }
  },
}
</script>

<style scoped lang="scss"></style>
